const FIREBASE_CONFIG = {
  apiKey: process.env.NEXT_PUBLIC_FB_APIKEY || 'mock_key',
  authDomain: process.env.NEXT_PUBLIC_FB_AUTHDOMAIN,
  projectId: process.env.NEXT_PUBLIC_FB_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FB_STORAGEBUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FB_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FB_APP_ID,
  measurementId: process.env.NEXT_PUBLIC_FB_MEASUREMENT_ID,
}

export const PATH_GENERATION_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 1000 : 30
export const HOME_EVENTS_FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 32 : 32
export const HOME_HOSTS_FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 50 : 36
export const HOST_DETAIL_UPCOMING_EVENTS_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 64 : 16
export const HOST_DETAIL_PAST_EVENTS_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 16 : 8
export const FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 250 : 50
export const ORDER_NOTIFICATION_FETCH_LIMIT = 5
export const UPSELL_RECOMMENDATION_LIMIT = 16
export const UPSELL_RECOMMENDATION_PER_TYPE_LIMIT = 4
export const SALES_ORDERS_FETCH_LIMIT = process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 500 : 50
export const EVENT_LIST_PAGINATION_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 50 : 10
export const COMMENT_LIST_PAGINATION_FETCH_LIMIT = 10
export const MINIMAL_COMMENT_LIST_PAGINATION_FETCH_LIMIT = 3
export const GUEST_LIST_PAGINATION_FETCH_LIMIT =
  process.env.NEXT_PUBLIC_APP_ENV === 'production' ? 500 : 50

export default FIREBASE_CONFIG

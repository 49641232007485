import cn from 'clsx'
import { getAvailableTickets } from 'utils/Events'
import { getSimplifiedPriceLocale } from 'utils/Pricing'

const TicketPrice = ({
  tickets,
  showPrice,
  isMinimal = false,
  customClasses = '',
  currency = 'GBP',
}) => {
  if (!showPrice || !tickets?.length) return null

  const availableTickets = getAvailableTickets(tickets)
  const priceRange = availableTickets?.map(t => t?.price)
  const lowestPrice = Math.min(...priceRange)
  const highestPrice = Math.max(...priceRange)

  const freeEvent = lowestPrice === 0 && highestPrice === 0

  const className = cn(
    'flex items-center justify-between rounded-lg',
    isMinimal ? 'py-0' : 'py-1',
    customClasses
  )

  if (lowestPrice == null || lowestPrice === undefined || lowestPrice === Infinity) return null
  else if (freeEvent) return <p className={className}>Free Entry</p>
  else return <p className={className}>{getSimplifiedPriceLocale(lowestPrice, currency, true)}</p>
}

export default TicketPrice

// We are using a variety of icons from react-icons.
// Let's use this to centralize and standardize our icon usage.
// The goal is to
// 1. use icons from commonly used icon families as much as possible.
// 2. use same icons across multiple places in UI
import { AiFillHeart as FillHeartIcon } from '@react-icons/all-files/ai/AiFillHeart'
import { AiFillIdcard as IdCard } from '@react-icons/all-files/ai/AiFillIdcard'
import { AiFillLinkedin as LinkedIn } from '@react-icons/all-files/ai/AiFillLinkedin'
import { AiOutlineAlert as Alert } from '@react-icons/all-files/ai/AiOutlineAlert'
import { AiOutlineHeart as OutlineHeartIcon } from '@react-icons/all-files/ai/AiOutlineHeart'
import { BsCalendar4Event as ICalendar } from '@react-icons/all-files/bs/BsCalendar4Event'
import { BsFillSunFill as Sun } from '@react-icons/all-files/bs/BsFillSunFill'
import { BsMoonStarsFill as MoonFill } from '@react-icons/all-files/bs/BsMoonStarsFill'
import { CiDark as Moon } from '@react-icons/all-files/ci/CiDark'
import { FaCircleUser as UserCircle } from '@react-icons/all-files/fa6/FaCircleUser'
import { FaFacebook as Facebook } from '@react-icons/all-files/fa6/FaFacebook'
import { FaInstagram as Instagram } from '@react-icons/all-files/fa6/FaInstagram'
import { FaCircle as IFilledCircle } from '@react-icons/all-files/fa/FaCircle'
import { FaHeart as IFilledHeart } from '@react-icons/all-files/fa/FaHeart'
import { FaRegLaughBeam as ILaugh } from '@react-icons/all-files/fa/FaRegLaughBeam'
import { FaWalking as WalkingIcon } from '@react-icons/all-files/fa/FaWalking'
import { FiChevronDown as IChevronDown } from '@react-icons/all-files/fi/FiChevronDown'
import { FiChevronLeft as IChevronLeft } from '@react-icons/all-files/fi/FiChevronLeft'
import { FiChevronRight as IChevronRight } from '@react-icons/all-files/fi/FiChevronRight'
import { FiChevronUp as IChevronUp } from '@react-icons/all-files/fi/FiChevronUp'
import { FiClock as IClock } from '@react-icons/all-files/fi/FiClock'
import { FiExternalLink as ExternalLink } from '@react-icons/all-files/fi/FiExternalLink'
import { FiSend as SubmitLink } from '@react-icons/all-files/fi/FiSend'
import { FiSettings as Settings } from '@react-icons/all-files/fi/FiSettings'
import { FiShoppingCart as ShoppingCart } from '@react-icons/all-files/fi/FiShoppingCart'
import { FiTrash2 as Trash } from '@react-icons/all-files/fi/FiTrash2'
import { GoArrowSwitch as SwtichToSellingOrHosting } from '@react-icons/all-files/go/GoArrowSwitch'
import { GoComment as CommentIcon } from '@react-icons/all-files/go/GoComment'
import { HiHome as Home } from '@react-icons/all-files/hi/HiHome'
import { IoArrowBack as IArrowLeft } from '@react-icons/all-files/io5/IoArrowBack'
import { IoArrowDownOutline as IArrowDown } from '@react-icons/all-files/io5/IoArrowDownOutline'
import { IoArrowForward as IArrowRight } from '@react-icons/all-files/io5/IoArrowForward'
import { IoArrowUpOutline as IArrowUp } from '@react-icons/all-files/io5/IoArrowUpOutline'
import { IoCheckbox as ICheckbox } from '@react-icons/all-files/io5/IoCheckbox'
import { IoTicketSharp as TicketIcon } from '@react-icons/all-files/io5/IoTicketSharp'
import { IoIosLogIn as LogIn } from '@react-icons/all-files/io/IoIosLogIn'
import { IoIosLogOut as LogOut } from '@react-icons/all-files/io/IoIosLogOut'
import { IoIosSearch as ISearch } from '@react-icons/all-files/io/IoIosSearch'
import { LuPenTool as PenTool } from '@react-icons/all-files/lu/LuPenTool'
import { MdCelebration as CelebrationIcon } from '@react-icons/all-files/md/MdCelebration'
import { MdOutlineFeed as IOutlineFeed } from '@react-icons/all-files/md/MdOutlineFeed'
import { MdVerified as IFilledVerified } from '@react-icons/all-files/md/MdVerified'
import { RiAccountCircleLine as AccountSettings } from '@react-icons/all-files/ri/RiAccountCircleLine'
import { RiLayout6Fill } from '@react-icons/all-files/ri/RiLayout6Fill'
import { RxCrossCircled as Cross } from '@react-icons/all-files/rx/RxCrossCircled'
import { RxHamburgerMenu as HamBurgerMenu } from '@react-icons/all-files/rx/RxHamburgerMenu'
import { SiGooglemeet as Meet } from '@react-icons/all-files/si/SiGooglemeet'
// import {RiLightbulbFlashLine as IBulb} from '@react-icons/all-files/ri/RiLightbulbFlashLine'
import { TbBulb as IBulb } from '@react-icons/all-files/tb/TbBulb'
import { TbHelpSquareRounded as Help } from '@react-icons/all-files/tb/TbHelpSquareRounded'
import { TbMapPins as IMapPins } from '@react-icons/all-files/tb/TbMapPins'

const Icons = {
  Home,
  UserCircle,
  TicketIcon,
  AccountSettings,
  SwtichToSellingOrHosting,
  ShoppingCart,
  HamBurgerMenu,
  Help,
  IdCard,
  LogIn,
  LogOut,
  Settings,
  Sun,
  Meet,
  Moon,
  MoonFill,
  RiLayout6Fill,
  PenTool,
  Alert,
  Cross,
  Trash,
  ExternalLink,
  IOutlineFeed,
  OutlineHeartIcon,
  FillHeartIcon,
  CommentIcon,
  IFilledHeart,
  IFilledCircle,
  IFilledVerified,
  CelebrationIcon,
  WalkingIcon,
  IChevronDown,
  IChevronLeft,
  IChevronRight,
  IChevronUp,
  ICalendar,
  IClock,
  IMapPins,
  ISearch,
  ILaugh,
  SubmitLink,
  IBulb,
  IArrowRight,
  IArrowDown,
  IArrowLeft,
  IArrowUp,
  // Socials
  LinkedIn,
  Facebook,
  Instagram,
  ICheckbox,
}

export default Icons

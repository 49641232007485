// import { EURO_COUNTRIES, SUPPORTED_COUNTRIES } from 'consts'
// import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setVisitorIP } from 'store/reducers'

// import { customRound } from 'utils/Pricing'

export default function useCountryLookup() {
  const dispatch = useDispatch()

  const getVisitorIP = async () => {
    try {
      const response = await fetch('https://api.ipify.org/?format=json')
      const data = await response.json()
      dispatch(setVisitorIP(data?.ip))
    } catch (err) {
      console.error('@getVisitorIP', err)
    }
  }

  // cascaded call from above
  // const getCountry = async visitorIP => {
  //   try {
  //     const response = await fetch(`https://api.country.is/${visitorIP}`)
  //     const data = await response.json()
  //     dispatch(setVisitorIPCountry(data?.country))
  //   } catch (err) {
  //     console.error('@getCountry', err)
  //   }
  // }

  //   const fetchExchange = async () => {
  //     await getVisitorIP()

  //     if (!SUPPORTED_COUNTRIES.includes(visitorIPCountry)) {
  //       setLoading(false)
  //       return 1
  //     }

  //     try {
  //       const fetchURL = `https://api.currencyapi.com/v3/latest?apikey=${currencyApiKey}&currencies=EUR%2CUSD&base_currency=GBP`
  //       const response = await fetch(fetchURL)
  //       const res = await response.json()
  //       dispatch(setExchange(res.data))
  //     } catch (err) {
  //       console.error('@fetchExchange:', err)
  //     } finally {
  //       setLoading(false)
  //     }
  //   }

  //   const fetchConversion = () => {
  //     if (
  //       exchange === 1 &&
  //       (visitorIPCountry === 'US' || EURO_COUNTRIES.includes(visitorIPCountry))
  //     ) {
  //       return 1
  //     } else if (visitorIPCountry === 'US') {
  //       return exchange?.USD?.value || 'loading...'
  //     } else if (EURO_COUNTRIES.includes(visitorIPCountry)) {
  //       return exchange?.EUR?.value || 'loading...'
  //     }
  //     return 1
  //   }

  //   // Support for UK, US and Euro countries
  //   const getPriceLocale = (price, omitUK = false) => {
  //     if (loading) return 'Loading...'

  //     if (omitUK) return

  //     const convertedPrice = parseFloat(price * fetchConversion())
  //     const roundedPrice = customRound(convertedPrice) // number
  //     const smartlyRoundedPrice = roundedPrice % 1 === 0 ? roundedPrice : roundedPrice.toFixed(2)

  //     // let formattedPrice = `£${Number(roundedPrice)}`
  //     let formattedPrice = `£${smartlyRoundedPrice}` // string

  //     if (
  //       exchange === 1 &&
  //       (visitorIPCountry === 'US' || EURO_COUNTRIES.includes(visitorIPCountry))
  //     ) {
  //       return formattedPrice
  //     } else if (visitorIPCountry === 'US') {
  //       formattedPrice = `$${roundedPrice}`
  //     } else if (EURO_COUNTRIES.includes(visitorIPCountry)) {
  //       if (isNaN(roundedPrice)) {
  //         formattedPrice = `£${price}`
  //       } else {
  //         formattedPrice = `${roundedPrice}€`
  //       }
  //     }

  //     return formattedPrice
  //   }

  //   const getPriceLocaleOnEventCards = (price = 0, omitUK = false) => {
  //     if (omitUK) return

  //     if (price === 0) return 'Free'

  //     return getPriceLocale(price, omitUK)
  //   }

  return {
    getVisitorIP,
    // getCountry,
    // fetchExchange,
    // fetchConversion,
    // getPriceLocale,
    // getPriceLocaleOnEventCards,
  }
}

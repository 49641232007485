import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore'
import { db } from 'services/FirebaseClient'

export const readSpecificDiscountOfHost = async (discountName, hostId) => {
  if (!discountName) return

  const discountRef = query(
    collection(db, 'discounts'),
    where('hostId', '==', hostId),
    where('code', '==', discountName.toLowerCase())
  )
  try {
    const discountSnap = await getDocs(discountRef)
    const discount = discountSnap.docs.map(doc => {
      return { ...doc.data(), id: doc?.id }
    })

    return discount[0]
  } catch (err) {
    console.log('Error fetching discount', err)
    throw err
  }
}

export const updateDiscountById = async (discountId, payload) => {
  if (!discountId || !payload) return
  console.log('payload', payload)
  const docRef = doc(db, 'discounts', discountId)

  try {
    await updateDoc(docRef, payload)
  } catch (err) {
    console.log('Error updating discount', err)
    throw err
  }
}

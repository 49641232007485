// In firebase data is heavily duplicated,
// For eg: every order will copy the entire event data object and host object
// In doing so we don't want to leak sensitive information within orders collection
// So we extract only the public information from the event and host objects

export const extractPublicInfoFromUserRecord = user => {
  const userPublicInfo = {
    userId: user?.id || '',
    email: user?.email || '',
    username: user?.username || '',
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    profileImage: user?.profileImage || '',
    phone: user?.phone || '',
    marketingEmailPreferences: user?.marketingEmailPreferences || true,
    // purchasedTickets: user?.purchasedTickets || [], // perhaps we don't need this
    signUpDate: user?.signUpDate || '',
    userLocation: user?.userLocation || {},
  }

  return userPublicInfo
}

export const extractPublicInfoFromHostRecord = host => {
  const hostPublicInfo = {
    id: host?.id ?? '',
    bio: host?.bio ?? '',
    userType: host?.userType ?? '',
    username: host?.username ?? '',
    firstName: host?.firstName ?? '',
    lastName: host?.lastName ?? '',
    hostTitle: host?.hostTitle ?? '',
    email: host?.email ?? '',
    phone: host?.phone ?? '',
    profileImage: host?.profileImage ?? '',
    coverImage: host?.coverImage ?? '',
    signUpDate: host?.signUpDate ?? '',
    venues: host?.venues ?? [],
    isVerified: host?.isVerified || false,
    summary: extractPublicSummaryofHost(host?.summary),
  }

  return hostPublicInfo
}

export const extractPublicSummaryofHost = summary => {
  return {
    ticketCount: summary?.ticketCount || 0,
    orderCount: summary?.orderCount || 0,
    upcomingPublicEvents: summary?.upcomingPublicEvents || 0,
  }
}

export const extractPublicInfoFromEventRecord = event => {
  const eventPublicInfo = {
    id: event?.id ?? '',
    slug: event?.slug ?? '',
    eventId: event?.eventId ?? '',
    name: event?.name ?? '',
    description: event?.description ?? '',
    currency: event?.currency ?? 'GBP',
    category: event?.category ?? '',
    image: event?.image ?? '', // TODO: deprecate this in future
    images: event?.images ?? [],
    eventType: event?.eventType ?? '',
    coords: event?.coords ?? {},
    isPublished: event?.isPublished,
    isPrivate: event?.isPrivate,
    isExternal: event?.isExternal,
    createdAt: event?.createdAt ?? '',
    rsvp: event?.rsvp ?? '',
    // createdAt: new Timestamp(event?.createdAt?.seconds ?? event?.createdAt?._seconds),
    date: event?.date ?? '',
    endDateTime: event?.endDateTime ?? '',
    hostId: event?.hostId ?? '',
    // date: new Timestamp(event?.date?.seconds ?? event?.date?._seconds),
    venue: event?.venue ?? {},
    tickets: event?.tickets ?? [], // TODO: rethink about this in future
    tags: event?.tags ?? [],
    summary: event?.summary ?? {},
    host: event?.host ?? {},
  }

  return eventPublicInfo
}

export const extractPublicInfoFromDiscountRecord = discount => {
  const discountPublicInfo = {
    id: discount?.id ?? '',
    name: discount?.name ?? '',
    claim: discount?.claim ?? '',
    code: discount?.code ?? '',
    type: discount?.type ?? '',
    value: discount?.value ?? 0,
    isActive: discount?.isActive ?? false,
    isPrivate: discount?.isPrivate ?? false,
    startDateTime: discount?.startDateTime ?? '',
    endDateTime: discount?.endDateTime ?? '',
    hostId: discount?.hostId ?? '',
  }

  return discountPublicInfo
}

// #############################################################################
// #                                BUYER                                      #
// #############################################################################
import { FETCH_LIMIT, ORDER_NOTIFICATION_FETCH_LIMIT } from 'consts/Firebase'
import MSG from 'consts/Messages'
import {
  collection,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
} from 'firebase/firestore'
import { toast } from 'react-toastify'
import { db } from 'services/FirebaseClient'
import { sanitizeObjForUpload } from 'utils/Objects'

// #############################################################################
// #                                CREATE                                     #
// #############################################################################
export const createOrderDoc = async (orderId, payload) => {
  const sanitizedPayload = sanitizeObjForUpload(payload)
  const docRef = doc(db, 'orders', orderId)

  try {
    await setDoc(docRef, { ...sanitizedPayload, createdAt: serverTimestamp() })
  } catch (err) {
    console.error('Error creating new order', err)
    throw err
  }
}

// #############################################################################
// #                                READ                                       #
// #############################################################################
export const readPublicOrders = async (fetchLimit = FETCH_LIMIT) => {
  // if (!eventId) return

  const q = query(
    collection(db, 'orders'),
    where('isCancelled', '==', false),
    where('event.isPrivate', '==', false),
    // where('eventId', '==', eventId),
    orderBy('createdAt', 'desc'),
    limit(fetchLimit)
  )

  try {
    const res = await getDocs(q)
    const orders = res.docs.map(doc => doc.data())

    return orders
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, { toastId: 'get-order-failed' })
    console.error('🚩 @readPublicOrders', error.message)

    return []
  }
}

export const readPublicOrdersByEventId = async (eventId, limit = FETCH_LIMIT) => {
  if (!eventId) return

  const q = query(
    collection(db, 'orders'),
    where('isCancelled', '==', false),
    where('eventId', '==', eventId),
    limit(limit)
  )

  try {
    const res = await getDocs(q)
    const orders = res.docs.map(doc => doc.data())

    return orders
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, { toastId: 'get-order-failed' })
    console.error('🚩 @readPublicOrdersByEventId', error.message)
  }
}

export const readOrdersByBuyerId = async (userId, callback = () => {}) => {
  if (!userId) return null

  const q = query(collection(db, 'orders'), where('user.userId', '==', userId), limit(FETCH_LIMIT))

  try {
    onSnapshot(q, snapshot => {
      const purchaseOrders = snapshot.docs.map(doc => doc.data())
      callback(purchaseOrders)
    })
  } catch (error) {
    console.error('Error @readOrdersByBuyerId', error)
  }
}

// Helper functions to fetch orders
const fetchOrdersByField = async (field, value, fetchLimit) => {
  if (!value) return []

  try {
    const ordersQuery = query(
      collection(db, 'orders'),
      where(field, '==', value),
      // where('isCancelled', '==', false),
      orderBy('createdAt', 'desc'),
      limit(fetchLimit)
    )

    const snapshot = await getDocs(ordersQuery)
    return snapshot.docs.map(doc => ({ ...doc.data(), priority: field }))
  } catch (error) {
    console.error(`🚩 Error fetching orders by ${field}:`, error.message)
    return []
  }
}

const fetchGlobalOrders = async fetchLimit => {
  try {
    const ordersQuery = query(
      collection(db, 'orders'),
      // where('isCancelled', '==', false),
      orderBy('createdAt', 'desc'),
      limit(fetchLimit)
    )

    const snapshot = await getDocs(ordersQuery)
    return snapshot.docs.map(doc => ({ ...doc.data(), priority: 'global' }))
  } catch (error) {
    console.error('🚩 Error fetching global orders:', error.message)
    return []
  }
}

// Main function to read and mix orders
export const readRecentOrders = async (
  eventId,
  hostId,
  eventType,
  fetchLimit = ORDER_NOTIFICATION_FETCH_LIMIT
) => {
  try {
    // Fetch all types of orders in parallel
    const [eventOrders, hostOrders, typeOrders, globalOrders] = await Promise.all([
      fetchOrdersByField('eventId', eventId, fetchLimit),
      fetchOrdersByField('hostId', hostId, fetchLimit),
      fetchOrdersByField('event.eventType', eventType, fetchLimit),
      fetchGlobalOrders(fetchLimit),
    ])

    // Function to rebalance array by simple interleaving of different sources
    const rebalanceOrders = () => {
      // Create arrays of orders from different sources
      const orderArrays = [eventOrders, hostOrders, typeOrders, globalOrders]

      const result = []
      const seen = new Set()

      // Find the maximum length among all arrays
      const maxLength = Math.max(...orderArrays.map(arr => arr.length))

      // Iterate through each position (first elements, second elements, etc.)
      for (let i = 0; i < maxLength; i++) {
        // Go through each array for this position
        for (const orderArray of orderArrays) {
          // Check if this position exists in the current array
          if (i < orderArray.length) {
            const order = orderArray[i]
            // Add to result only if not seen before
            if (!seen.has(order.id)) {
              seen.add(order.id)
              result.push(order)
            }
          }
        }
      }

      return result
    }

    return rebalanceOrders()
  } catch (error) {
    toast.error(MSG.ERROR.BUYER.FETCH_ORDER_DETAILS, { toastId: 'read-recent-orders-failed' })
    console.error('🚩 @readRecentOrders', error.message)
    return []
  }
}

// #############################################################################
// #                                UPDATE                                     #
// #############################################################################

// #############################################################################
// #                                DELETE                                     #
// #############################################################################

import { CLOUD_FUNCTIONS } from 'consts/CloudFunctions'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'
import useMetaInfo from 'services/client/Meta'
import { debounce } from 'utils/FbConversion'
import getRequestOptions from 'utils/Headers'
import isObjectEmpty from 'utils/Objects'
import { ptp } from 'utils/Pricing'

const useFbConversion = () => {
  const user = useSelector(state => state?.pr?.user?.info)
  const vaildPhoneNumber = user?.phone === '+44' ? '' : user?.phone
  const { metaInfo } = useMetaInfo()

  const executeFbConversion = async (fbEventName = 'ViewContent', payment = {}, cart = []) => {
    if (window.location.origin !== process.env.NEXT_PUBLIC_APP_URL) return
    if (isObjectEmpty(user)) return

    const cartWithTicketCount = cart?.filter(item => item?.count > 0)

    if (cart?.length !== 0 && cartWithTicketCount.length === 0) return

    const payload = {
      user: { ...user, ...{ phone: vaildPhoneNumber ?? '' } },
      metaInfo,
      eventName: fbEventName,
      payment: { ...payment, ...{ amount: ptp(payment?.amount, false) } },
      cart: cartWithTicketCount,
    }

    const url = CLOUD_FUNCTIONS.SEND_FB_CONVERSION_PIXEL
    const requestOptions = getRequestOptions(payload, 'POST')

    try {
      const response = await fetch(url, requestOptions)
      if (response?.ok) console.log('fb conversion success', fbEventName)
    } catch (e) {
      console.log('error in fb conversion', e)
    }
  }

  const debouncedFBConversion = useCallback(
    debounce(store => {
      executeFbConversion('AddToCart', {}, store)
    }, 1000),
    []
  )

  return { executeFbConversion, debouncedFBConversion }
}

export default useFbConversion

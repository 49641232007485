import {
  DATE_FORMAT,
  EVENT_CARD_DATE_FORMAT,
  HUMAN_FRIENDLY_DATE_TIME_FORMAT,
  TIME_FORMAT,
} from 'consts'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import isToday from 'dayjs/plugin/isToday'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(isBetween)

// So Firebase Client and Server produce slightly different timestamps
// https://github.com/firebase/firebase-admin-node/issues/1404
// https://github.com/firebase/firebase-admin-node/issues/657
export const unifyTimestamps = ts => {
  if (!ts) return null

  const _ts = {
    seconds: ts.seconds ?? ts._seconds,
    nanoseconds: ts.nanoseconds ?? ts._nanoseconds,
  }

  return _ts
}

// Returns a string formatted as a date.
export const formatDate = (date, format = DATE_FORMAT) => formatDateTime(date, format)

// Returns a string representation of the given date in the given format.
export const formatTime = (date, format = TIME_FORMAT) => formatDateTime(date, format)

/**
 * Formats a JavaScript Date object or Firebase Timestamp using dayjs.
 *
 * @function
 * @param {(Date|Object)} date - The JavaScript Date object or Firebase Timestamp.
 * @param {string} [format=HUMAN_FRIENDLY_DATE_TIME_FORMAT] - The desired date format.
 * @returns {string} The formatted date string or an empty string if input is faulty.
 */
export const formatDateTime = (date, format = HUMAN_FRIENDLY_DATE_TIME_FORMAT) => {
  // Determine if it's a JS date object
  if (date instanceof Date) {
    return dayjs(date).format(format)
  }

  // Determine if it's a Firebase Timestamp
  if (date?._seconds || date?.seconds) {
    const ms = (date._seconds ?? date.seconds) * 1000
    return dayjs(ms).format(format)
  }

  // Return empty string for faulty input
  return ''
}

/**
 * Returns the difference between two dates in a human readable format.
 *
 * @param {string|object} from - The date to be compared from.
 * @param {string} to - The date to compare to. Defaults to today.
 * @param {boolean} returnString - Whether to return the difference as a string or as a duration object.
 * @param {boolean} usePrefixSuffix - Whether to use prefix/suffix (e.g. 'in'/'ago') in the returned string.
 *
 * @returns {string|object} The difference between the two dates.
 */
export const durationBetween = (
  from,
  to = new Date(),
  returnString = true,
  usePrefixSuffix = true
) => {
  const a = getDayjsObj(from)
  const b = getDayjsObj(to)

  if (!a || !b) return

  const difference = a.diff(b)

  if (returnString) {
    if (difference < 0) return usePrefixSuffix ? a.fromNow() : a.fromNow(true)

    return usePrefixSuffix ? b.to(a) : b.to(a, true)
  }

  // Returns duration object when returnString is false
  return {
    years: a.diff(b, 'year'),
    months: a.diff(b, 'month'),
    days: a.diff(b, 'day'),
    hours: a.diff(b, 'hour'),
    minutes: a.diff(b, 'minute'),
    seconds: a.diff(b, 'second'),
  }
}

export const formatDateTimeRange = (start, end, format = HUMAN_FRIENDLY_DATE_TIME_FORMAT) => {
  const startObj = getDayjsObj(start)
  const endObj = getDayjsObj(end)
  if (!startObj) return ''

  return dayjs(startObj).format(format) + (endObj ? ' - ' + dayjs(endObj).format(format) : '')
}

/**
 * Gets a Day.js object from a Date object or a Firebase Timestamp.
 * @param {Date | firebase.firestore.Timestamp} date - The date object to convert.
 * @returns {dayjs.Dayjs} The Day.js object.
 */
export const getDayjsObj = date => {
  if (!date) return null

  // Handling Date object
  if (date instanceof Date) {
    return dayjs(date)
  }

  // Handling serialized Firebase timestamp from client SDK
  if (date && 'seconds' in date) {
    const jsDate = new Date(date.seconds * 1000)

    return dayjs(jsDate)
  }

  // Handling serialized Firebase timestamp from admin SDK
  if (date && '_seconds' in date) {
    const jsDate = new Date(date._seconds * 1000)

    return dayjs(jsDate)
  }

  return null
}

//rounding the time to it's nearest hour
export const roundToNearestHour = timeString => {
  let [time, period] = timeString.split(' ')
  let [hour, minute] = time.split(':').map(Number) // Convert strings to numbers directly

  if (minute >= 30) {
    hour += 1
  }

  if (hour === 12) {
    period = period === 'AM' ? 'PM' : 'AM'
  } else if (hour > 12) {
    hour -= 12
    if (hour === 12) {
      // Check after reducing hour to handle case like 12:45 AM -> 01:00 PM
      period = period === 'AM' ? 'PM' : 'AM'
    }
  }

  // Format the hour to always be two digits
  const formattedHour = String(hour).padStart(2, '0')

  return `${formattedHour}:00 ${period}`
}

//timestamps i.e 12:00 AM to 11:59 PM
export const timestamps = Array.from({ length: 24 }, (_, i) => {
  const hour = i % 12 || 12
  const period = i < 12 ? 'AM' : 'PM'

  return `${String(hour).padStart(2, '0')}:00 ${period}`
})

//formatting the data on the basis of timestamps for Orders, Gross Revenue and Net Revenue
export const generateFormattedOrders = (orders, timestamps) => {
  const formattedOrders = []

  timestamps.forEach(timestamp => {
    formattedOrders.push({
      date: timestamp,
      TicketSales: 0,
      Orders: 0,
      Gross: 0,
      Net: 0,
      Paid: 0,
      Free: 0,
    })
  })

  orders
    ?.map(item => ({
      name: item?.event?.name,
      date: roundToNearestHour(formatTime(item?.createdAt)),
      TicketSales: item?.ticketList?.length,
      Gross: (item?.summary?.grandTotal ?? 0) / 100,
      Net: (item?.summary?.subTotal ?? 0) / 100,
      Paid: item?.summary?.freeTicketCount ?? 0,
      Free: item?.summary?.paidTicketCount ?? 0,
    }))
    .forEach(({ date, TicketSales, Gross, Net, Paid, Free }) => {
      const index = formattedOrders.findIndex(entry => entry.date === date)
      if (index !== -1) {
        formattedOrders[index].Orders += 1
        formattedOrders[index].Gross += Gross || 0
        formattedOrders[index].Net += Net || 0
        formattedOrders[index].Paid += Paid || 0
        formattedOrders[index].Free += Free || 0
        formattedOrders[index].TicketSales += TicketSales || 0
      }
    })

  return formattedOrders
}

//calculation of data for the ranges selected
// Aggregate data
export const rangeData = (ordersData, startDate, endDate) => {
  return ordersData
    ?.filter(item => {
      const itemDate = getDayjsObj(item?.createdAt)

      return itemDate.isBetween(startDate, endDate, 'day', '[]') // '[]' includes start and end dates

      // return (
      //   (itemDate.isSame(startDate, 'day') || itemDate.isAfter(startDate, 'day')) &&
      //   (itemDate.isSame(endDate, 'day') || itemDate.isBefore(endDate, 'day'))
      // )
    })
    .reduce((accumulator, currentItem) => {
      const itemDate = getDayjsObj(currentItem?.createdAt).format(EVENT_CARD_DATE_FORMAT)
      accumulator[itemDate] = accumulator[itemDate] || {
        Orders: 0,
        Gross: 0,
        Net: 0,
        TicketSales: 0,
        Paid: 0,
        Free: 0,
      }
      accumulator[itemDate].Orders++
      accumulator[itemDate].Gross += currentItem?.summary?.grandTotal ?? 0
      accumulator[itemDate].Net += currentItem?.summary?.subTotal ?? 0
      accumulator[itemDate].TicketSales += currentItem?.ticketList?.length ?? 0
      accumulator[itemDate].Paid += currentItem?.summary?.paidTicketCount ?? 0
      accumulator[itemDate].Free += currentItem?.summary?.freeTicketCount ?? 0

      return accumulator
    }, {})
}

export const isBetweenTwoDates = (startDate, endDate) => {
  const now = dayjs()

  const start = getDayjsObj(startDate)
  const end = getDayjsObj(endDate)

  return now.isBetween(start, end)
}

export const isDateBetweenTwoDates = (startDate, endDate, targetDate) => {
  const normalizedTargetDate = dayjs(targetDate)

  const start = getDayjsObj(startDate)
  const end = getDayjsObj(endDate)

  return normalizedTargetDate.isBetween(start, end, 'day', '[]')
}

export const formatTimeTo12hr = timeStr => {
  if (!timeStr || typeof timeStr !== 'string') return ''

  try {
    // Basic validation with regex (24-hour format: 00:00 to 23:59)
    const timeRegex = /^([01]\d|2[0-3]):([0-5]\d)$/
    if (!timeRegex.test(timeStr)) return ''

    const [hours, minutes] = timeStr.split(':')

    const date = new Date()
    date.setHours(parseInt(hours, 10))
    date.setMinutes(parseInt(minutes, 10))

    return formatTime(date, TIME_FORMAT)
  } catch (error) {
    console.warn('Error formatting time to 12hr format:', error)
    return ''
  }
}

import useCountryLookup from 'hooks/useCountryLookup'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getCountryCodeByTimezone } from 'utils/FbConversion'

// import { getGeoLocation } from './GeoLocation'

// Meta info about users is tracked here

const useMetaInfo = () => {
  const { getVisitorIP } = useCountryLookup()

  useEffect(() => {
    getVisitorIP()
  }, [])

  const visitorIP = useSelector(state => state?.pr?.ui?.visitorIP) ?? ''
  const visitorIPCountry = getCountryCodeByTimezone()

  const metaInfo = {
    publicIP: visitorIP,
    country: visitorIPCountry,
    location: {
      origin: window?.location?.origin || 'N/A',
      href: window?.location?.href || 'N/A',
    },
    navigator: {
      // gpu: navigator.gpu, // TODO: Prevent this from sending GPU Object
      // keyboard: navigator.keyboard,// TODO: Prevent this from sending Keyboard Object
      language: navigator?.language || 'N/A',
      userAgent: navigator?.userAgent || 'N/A',
      deviceMemory: navigator?.deviceMemory || 'N/A',
      cookieEnabled: navigator?.cookieEnabled || 'N/A',
      pdfViewerEnabled: navigator?.pdfViewerEnabled || 'N/A',

      // To be deprecated soon
      platform: navigator?.platform ?? 'deprecated',
      product: navigator?.product ?? 'deprecated',
      vendor: navigator?.vendor ?? 'deprecated',
      // geoLocation: getGeoLocation(), // TODO: Fix this
    },
  }

  return { metaInfo }
}
export default useMetaInfo

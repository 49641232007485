import ROUTES from 'consts/Routes'

import isObjectEmpty from './Objects'

export const gethostName = host => {
  if (!host) return

  if (host?.hostTitle) return host?.hostTitle
  else {
    // Don't reveal full name here
    // Only reveal John Doe as John D.

    return host?.firstName + ' ' + host?.lastName?.substring(0, 1)
  }
}

export const getHostRoute = host => {
  if (!host || isObjectEmpty(host)) return ROUTES.ROOT

  const username = host?.username

  if (username) return ROUTES.BOOKING_WIDGET_USERNAME.replace(':username', username)

  return ROUTES.BOOKING_WIDGET.replace(':hostId', host?.id)
}

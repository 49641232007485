import React from 'react'
import { useSelector } from 'react-redux'

interface MessageObject {
  title?: string
  description?: string
}

interface RootLoadingState {
  pr: {
    loading: {
      isLoading: boolean
      message: string | MessageObject
    }
  }
}

const LoadingOverlay = () => {
  const globalLoading = useSelector((state: RootLoadingState) => state?.pr?.loading) || null

  if (!globalLoading?.isLoading) return null

  const renderContent = () => {
    if (!globalLoading.message) return null

    if (typeof globalLoading.message === 'string') {
      return <h4 className='text-lg font-bold'>{globalLoading.message}</h4>
    }

    const msg = globalLoading.message as MessageObject
    return (
      <>
        {msg.title && <h4 className='text-lg font-bold'>{msg.title}</h4>}
        {msg.description && <p className='text-warning'>{msg.description}</p>}
      </>
    )
  }

  return (
    <div className='pointer-events-auto fixed inset-0 bottom-0 left-0 right-0 top-0 z-[1500] flex flex-col items-center justify-center gap-2 bg-black bg-opacity-95 text-white'>
      <span className='loading-dot loading loading-lg text-secondary'></span>
      {renderContent()}
    </div>
  )
}

export default LoadingOverlay

export const debounce = (fn, delay = 1000) => {
  let timer

  const debounced = (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), delay)
  }

  debounced.cancel = () => {
    clearTimeout(timer)
    timer = null
  }

  return debounced
}

export const getCountryCodeByTimezone = () => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timezoneToCountry = {
    'Pacific/Midway': 'US',
    'Pacific/Honolulu': 'US',
    'America/Anchorage': 'US',
    'America/Los_Angeles': 'US',
    'America/Denver': 'US',
    'America/Chicago': 'US',
    'America/New_York': 'US',
    'America/Toronto': 'CA',
    'America/Halifax': 'CA',
    'America/St_Johns': 'CA',
    'America/Mexico_City': 'MX',
    'America/Bogota': 'CO',
    'America/Caracas': 'VE',
    'America/Santiago': 'CL',
    'America/Buenos_Aires': 'AR',
    'Atlantic/Azores': 'PT',
    'Europe/London': 'GB',
    'Europe/Berlin': 'DE',
    'Europe/Paris': 'FR',
    'Europe/Moscow': 'RU',
    'Asia/Dubai': 'AE',
    'Asia/Karachi': 'PK',
    'Asia/Kolkata': 'IN',
    'Asia/Bangkok': 'TH',
    'Asia/Shanghai': 'CN',
    'Asia/Tokyo': 'JP',
    'Australia/Sydney': 'AU',
    'Pacific/Auckland': 'NZ',
    'Asia/Katmandu': 'NP',
    'Europe/Istanbul': 'TR',
    'Europe/Kiev': 'UA',
    'Africa/Cairo': 'EG',
    'Africa/Nairobi': 'KE',
    'Africa/Johannesburg': 'ZA',
    'Asia/Seoul': 'KR',
    'Asia/Jakarta': 'ID',
    'Asia/Manila': 'PH',
    'Europe/Athens': 'GR',
    'America/Lima': 'PE',
    'America/Havana': 'CU',
    'Europe/Stockholm': 'SE',
    'Asia/Baghdad': 'IQ',
    'Asia/Tehran': 'IR',
    'Asia/Beirut': 'LB',
    'America/Montevideo': 'UY',
    'Asia/Yerevan': 'AM',
    'Asia/Baku': 'AZ',
    'Asia/Bishkek': 'KG',
    'Asia/Almaty': 'KZ',
  }

  return timezoneToCountry[timezone] || 'N/A'
}
